import React, { Component } from 'react';
import styled from 'styled-components';
import pimg from '../images/contact/4954.jpg'
import whatsimg from '../images/contact/whatsup.png'
import mailimg from '../images/contact/mail.png'
import phoneimg from '../images/contact/phone.png'
import locimg from '../images/contact/loc.png'

class contactutil extends Component {


state = {
    status: "",
    loading:false,
    contact:[{

        id:1,
        title:"Address",
        icon: `${locimg}`,
        link:"#",

        content: `#1869, 28th main, South end C cross, 9th block jayanagar east, 
Bangalore -560069 `

    },
    {

        id:2,
        title:"Phone",
        icon: phoneimg,
        content:"+91 8971 18 18 18 /  97421 22271 ",
        link:"#",

    },
    {

        id:3,
        title:"Mail",
        icon: mailimg,
        content:" interiors@designseries.co.in",
        link:"mailto:interiors@designseries.co.in",

    },
    {

        id:4,
        title:"WhatsApp",
        icon: whatsimg,
        link:"https://wa.me/918971181818 ",
        content:"Click for a Quick Chat"

    }]

}


submitForm(ev) {
    ev.preventDefault();

    this.setState({loading:true})
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
        this.setState({loading:false})
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
    


  }


    render() {

const address = this.state.contact;
const { status, loading} = this.state;

        return (
           

            <ContactWrap className="py-sm-0" style={{backgroundColor:"black", overflow:"hidden"}}>

            <div className="container-fluid p-sm-0">
       
                
                <div className="row">
         
     
         <div className="col-sm-5">

<img src={pimg} alt="phone" height="100%" width="100%"/>
         </div>
         
       
         <div className=" col-md-4 py-sm-5" id="cd2">
         <h2 className="text-white headc mb-5">LETS TALK</h2>
         <p className="my-0 ml-5  p-0 text-white" style={{fontWeight:"bold"}}>GET IN TOUCH WITH US</p>

         
    {
        address.map(item=>{
            return(<>
            <div className="address">
<a href={item.link}>

    <img  style={{marginTop:"1.1rem"}} src={item.icon} alt="imj" width="25px"/>
    
</a>
<a href={item.link} style={{textDecoration:"none"}}><p >{item.content}</p></a>


            </div>
            
            
            
            </>)
        })
    }
</div>

    
<div class="col-md-2  py-5 px-sm-0" id="cd1">
                  <p className="text-warning" style={{fontWeight:"bold"}}>DROP A QUERY !!</p>  
                    <form 
                    
                    onSubmit={(e)=>this.submitForm(e)}
                    action="https://formspree.io/xnqgdezq"
                    method="POST"
                    
                    
                    data-form-title="CONTACT US">
                      <input type="hidden" data-form-email="true"/>
                      <div class="form-group">
                        <input type="text" className="form-control" name="name" required placeholder="Name*" data-form-field="Name"/>
                      </div>
                      <div class="form-group">
                        <input type="email" className="form-control" name="email" required placeholder="Email*" data-form-field="Email"/>
                      </div>
                      <div class="form-group">
                        <input type="tel" className="form-control" name="phone" placeholder="Phone" data-form-field="Phone" required/>
                      </div>
                      <div class="form-group">
                        <textarea className="form-control" name="message" placeholder="Message" rows="7" data-form-field="Message"></textarea>
                      </div>
                      

                      {status === "SUCCESS" ? <div class="alert alert-success" role="alert">
                      Thanks, we will get in touch with you!
</div> :  <button type="submit" disabled={loading? true : false} className="btn">{loading? "Sending..." : "SUBMIT"}</button>
                     }
        {status === "ERROR" && <div class="alert alert-danger" role="alert">
  somthing went Wrong !
</div>}
                    </form>
                  </div>

<div className="col-sm-1"></div>


                </div>
            </div>



            {/* <iframe width="100%" src="https://www.google.com/maps/dir//Design+series+,+bangalore,+%231869,+28th+Main,+South+end+C+Cross,,+9th+Block+Jayanagar+East,bangalore-560069,+3rd+Phase,+JP+Nagar,+Bengaluru,+Karnataka+560069,+India/@12.911292,77.5897555,14.54z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae150f50c8e539:0xcdeaa8bc4c14b946!2m2!1d77.5959332!2d12.9158191" frameborder="0"></iframe> */}
         
            </ContactWrap>
          





        );
    }
}





const ContactWrap = styled.div`

button{

    font-weight:bold;
    color:white;
    background-color:#FF9609;

}
.headc{

    font-size:4rem;
    transform:translateX(-12rem);
    font-weight:bold;
    letter-spacing:0.2rem;



    display:block;
}

.container-fluid{
    padding:10px;
    background-color:black;

}

.address{

    display:flex;
    /* align-items:center; */
}

#cd1{
    width:100%;
    margin-top:5rem;
}

#cd2{
    box-sizing:border-box;

    
p{
    color:grey;
    font-size:18px;
    padding:15px 20px;

}
i{
    color:var(--mainBlue);
    padding:15px;
    font-size:32px;
    text-align:center;
   
  
 
}

}

@media(max-width:960px){


    #cd1{
    width:100%;
    margin-top:0.5rem;
}
.headc{
    font-size:3rem;
    transform:translateX(3rem);
    font-weight:bold;
    letter-spacing:0.2rem;

}
    button{

display:block;
margin:auto;
}

    #cd2{

        margin-top:4rem;

     
    }
i{
    color:var(--mainBlue);
    padding:5px;
    text-align:center;
   
    svg{

        display:block;
        margin:auto;
            font-size:42px;

    }
 
}

p{
    color:grey;
    font-size:18px;
    text-align:center;
    padding:5px 10px !important;


}
    
}
`;
export default contactutil;