import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo"
import Contactutil from '../util/contactutil'
// import PageHeader from '../util/pageHeader'
const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
   
{/* <PageHeader heading="Contact US" source="https://livedemo00.template-help.com/wt_prod-2267/images/breadcrumbs-background-01.jpg"/> */}
<Contactutil />

<div className="container-fluid pt-5" style={{backgroundColor:"black"}}>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.873996736874!2d77.59374451457725!3d12.915819090892446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150f50c8e539%3A0xcdeaa8bc4c14b946!2sDesign%20series%20%2C%20bangalore!5e0!3m2!1sen!2sin!4v1591763422928!5m2!1sen!2sin" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
   
  </Layout>
)




export default ContactPage